/*
 * Import preflight.css here to work around tailwind/radix CSS conflict. See:
 * https://github.com/tailwindlabs/tailwindcss/discussions/5969#discussioncomment-6967389
 */
@import "preflight.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@radix-ui/themes/styles.css";
/* body {
  background-image: linear-gradient(
    330deg,
    hsl(272, 53%, 50%) 0%,
    hsl(226, 68%, 56%) 100%
  );
} */

/* Negative margin on the right to account for scrollbar */
html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

/*
  react-remove-scroll (used by radix-ui) tries to remove the scrollbar when
  popups pop up (using !important, no less!). It doesn't play well with the
  above hack, so we need a further hack, using an #id to defeat the !important.
*/
body#_criblateBody {
  margin-right: 0 !important;
}
